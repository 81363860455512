$(function () {
//    console.log('isLive: ', $("#quiz_form").data('isLive'));
//    console.log('isPastLiveEvent: ', $("#quiz_form").data('isPastLiveEvent'));
//    console.log('result: ', (!$("#quiz_form").data('isLive') && !$("#quiz_form").data('isPastLiveEvent')));

    if (!$("#quiz_form").data('isLive') && !$("#quiz_form").data('isPastLiveEvent') && false) {
        $("#quiz_form").submit(function (event) {
            if (testQuiz() === false) {
                console.log('ok');
                return;
            } else {
                event.preventDefault();
                console.log(testQuiz());
                return false;
            }
        });
    }

    function testQuiz() {
        var errors = false;
        $('#quizAlert').hide();
        $('#quiz_form fieldset').removeClass('error');
        $('#quiz_form .form-check').removeClass('is-wrong');

        $("#quiz_form .form-check-input").each(function (index) {
            var container = $(this).closest('.form-check').addClass('checked');
            var fieldset = $(this).closest('fieldset');

            container.removeClass("is-good is-wrong");

            if ($(this).is(':checked')) {
                container.addClass('is-checked');
            } else {
                container.removeClass('is-checked');
            }

            if ($(this).is(':checked') == $(this).data('isRight')) {
                container.addClass('is-good');
            } else {
                container.addClass('is-wrong');
                fieldset.addClass('error');
                errors = errors++;
                $('#quizAlert').show();
            }
        });
        return errors;
    }
});