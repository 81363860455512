import 'bootstrap-select';
// import './user';
import './cart';
import './quiz';

var $ = require('jquery');
window.jQuery = $;
window.$ = $;

$(function () {
    var alertList = document.querySelectorAll('.alert')
    alertList.forEach(function (alert) {
        new bootstrap.Alert(alert)
    })


    $('#feedback-rating .c-rating').on('click', '.star', function () {
        event.preventDefault();
        var list = $(this).closest('.c-rating');
        var rating = list.find('button').index($(this)) + 1;
        if (rating) {
            $('#form_rating').val(rating);
            list.attr('data-rating-value', rating);
        }
    });

//    var videoEditModal = new bootstrap.Modal(document.getElementById('videoEditModal'), []);

    $('#ajax-freeaccess-cart').click(function (event) {
        event.preventDefault();
        $.ajax({
            url: $(this).attr('href'),
        }).done(function (data) {
            if (data.result) {
                $('#main-modal .modal-title').text('You\'re buing the free accesss');
                $('#main-modal').modal('show');
            } else {

            }
        });
    });

    $('.add-to-favourites').click(function (event) {
        event.preventDefault();
        var obj = $(this);
        var url = $(this).data('addUrl');
        console.log(url);
        $.ajax({
            url: url,
        }).done(function (data) {
            if (data.result) {
                console.log(data.result);
                $('#favourites-add .modal-body').text(data.result);
                $('#favourites-add').modal('show');
                if (data.success == 1) {
                    $('.video-' + data.video + ' .add-to-favourites').attr('disabled', true).addClass('disabled');
//                    obj.addClass('disabled').attr("disabled", true);
                }
            } else {

            }
        });
        return false;
    });

//    $('#afterLoginInfo').modal('show');
//    $('#popupModal').modal('show')
//            .on('shown.bs.modal', function (e) {
//                $('.modal-backdrop.show').addClass('opac');
//            });


//    var popoverCart = '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>';
//    var popoverContent = $('#btnShowBasket').data('content');
//    $('.video-share-popover').popover({
//        html: true,
//        trigger: 'focus',
//        template: popoverCart,
//        content: function () {
//            return '<h1>hello</h1>';
//        }
//    });


    $('.video-container, .card-btns').on('click', '.fbtn.share.android', function () {
        var title = $(this).data('title');
        var url = $(this).data('link');
        console.log($(this));
        console.log('title: ', title);
        console.log('url: ', url);
        if (navigator.share) {
            navigator.share({
                title: title,
                text: 'Look what I found. Amazing FREE Nurse Education and CE Credits',
                url: url,
            })
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.log('Error sharing', error));
        } else {
            alert('Share not supported on this browser, do it the old way.');
        }
    });

    $('.custom-file-input').change(function () {
        var fileName = $(this).val();
        console.log(fileName);
        $(this).next('.custom-file-label').addClass("selected").html(fileName);
    });
});