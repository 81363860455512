$(function () {

    var popoverContent = $('#btnShowBasket').data('bsContent');
    let addToCartModalElement = document.getElementById('video-cart-add');
    if (addToCartModalElement) {
        var addToCartModal = new bootstrap.Modal(addToCartModalElement, []);
    }

    let addBundleToCoursesModalElement = document.getElementById('unlimited-get-bundle');
    if (addBundleToCoursesModalElement) {
        var addBundleToCoursesModal = new bootstrap.Modal(addBundleToCoursesModalElement, []);
    }

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl, {
            html: true,
            placement: 'top',
            trigger: 'focus'
        });
    })

    var cartTriggerEl = document.getElementById('btnShowBasket');
    var cartPopover = bootstrap.Popover.getInstance(cartTriggerEl);


    /*
     *

     #btn{{ class }}Buy_{{ id }}

     .video-list
     .btn-add-bundle-cart
     .btn-add-video-cart

     .video-container
     .btn-add-bundle-cart.btn-get
     .btn-add-video-cart.btn-get

     *
     *
     */

    $('.video-list, .video-container, #video-cart-add').on('click', '.btn-add-bundle-cart, .btn-add-video-cart, .btn-buy-anyway', function (event) {
        event.preventDefault();
        var url = $(this).attr('href');
        console.log(url);
        buy(url);
    });
    function buy(url) {
        $('#video-cart-add .btn-buy-anyway').remove();
        $.ajax({
            url: url,
        }).done(function (data) {
            var addBtn = $('#btn' + data.class + 'Buy_' + data.id);
            if (data.confirm) {
                console.log('confirm');
                if (data.btn) {
                    $('#video-cart-add .modal-footer').prepend(data.btn);
                }
            } else if (data.conflict) {
                console.log('conflict');
            } else {
                console.log('not confirm');
                addBtn.removeClass('btn-add-video-cart btn-add-bundle-cart');
                changeGetBtn(addBtn, data.payUrl, data.class);
                $('#products_counter').text(data.products_lenght);
                $('#mobile_filter_products_counter').text(data.products_lenght);
                $('#filter_products_counter').text(data.products_lenght);
            }

            $('#video-cart-add .modal-body-text').html(data.message);
            addToCartModal.show();
        });
    }

    function changeGetBtn(btn, url, item_class) {
        if (btn.hasClass('btn-get')) {
            btn.text('Pay for this ' + item_class);
        } else {
            btn.find('span').text('Pay');
        }
        btn.attr('href', url);
    }

    $('.cart-table').on('click', '#btn-dicount-apply', function () {
        var code = $('#input-discount').val();
        $('#discount_action_labels').text('');
        if (!code) {
            console.log('no code!');
            return;
        }
        var discount = $(this).data('discount');
        var url = $(this).data('url');
        var parent = $(this).parent();
        var spinner = $('<div class="spinner-grow text-danger" role="status"><span class="sr-only visually-hidden">Loading...</span></div>');
        var messages = ['Accepted', 'Invalid Code', 'Used Before'];
        //  DISCOUNT_CODE_ACCEPTED = 0;
        //  DISCOUNT_CODE_NOT_FOUND = 1;
        //  DISCOUNT_CODE_USED_BY_USER = 2;
        $(this).replaceWith(spinner);
        $.ajax({
            url: url,
            method: 'POST',
            data: {
                code: code
            }
        }).done(function (json) {
            if (json.cart_summary) {
                $('#cart-total-discount').text('$' + json.cart_summary);
            } else {
                $('#discount_action_labels').text(json.message);
            }
            spinner.replaceWith('<button type="button" class="btn btn-dicount-apply btn-secondary btn-sm" id="btn-dicount-apply" data-url="' + url + '" data-discount="">Apply</button>');
        });
    });
    $('.video-list, .bundle').on('click', '.btn-unlimited-get-bundle', function () {
        console.log($(this).data('href'));
        var url = $(this).data('href');
        $.ajax({
            url: url,
            method: 'POST'
        }).done(function (json) {
            if (json.error) {
                if (Array.isArray(json.error)) {
                    var err = "There are errors:<br>";
                    $.each(json.error, function (index, value) {
                        err += value.violations[0].title + "<br>";
                    });
                    $('#unlimited-get-bundle .modal-body-text').html(err);
                } else {
                    $('#unlimited-get-bundle .modal-body-text').text(json.error);
                }
            } else {
                $('#unlimited-get-bundle .modal-body-text').text(json.success);
            }
            addBundleToCoursesModal.show();
        });
    });
    function errorMessage(errorCode) {
        var codes = {
            "0": 'no error',
            '1': 'there is error',
            '2': 'addition error',
            '4': 'no cart',
            '8': 'item in user cart',
            '16': 'item in user videos',
            '32': 'item in bundle',
            '64': 'item has video',
            '128': 'item to update',
            '256': 'item actual'
        };
        var message = '';
        $.each(codes, function (key, value) {
            if (key & errorCode) {
                a = (key & errorCode);
                message = message + "<br>[" + key + "] " + value;
            }
        });
        return message;
    }

});